import styled from 'styled-components'
import Colors from '../constants/Colors'

export const Description = styled.p`
  color: ${Colors.GOLDEN};
  margin: auto;
  padding: 8px;
  text-align: center;
  font-size: 16px;
`

export const Title = styled.h1`
  margin: auto;
  font-size: 36px;
  color: ${Colors.GOLDEN};
`

export const ProfilePic = styled.img`
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  width: 172px;
  float: right;
  margin: auto;
  border-color: ${Colors.GOLDEN};
`
