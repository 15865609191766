import { Fragment } from 'react'

import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Title, ProfilePic, Description } from './Styles'
import { GridContainer, Flex, FlexItem } from '../components/layout/Styles'
import Colors from '../constants/Colors'

import alex from '../assets/alex.jpg'

export const HeaderComponent = () =>
  <Fragment>
    <GridContainer
      backgroundColor={Colors.MEDIUM_BLUE}
      borderBottom="2px solid"
      borderBottomColor={Colors.GOLDEN}
    >
      <Title>Alex Larson</Title>
      <ProfilePic src={alex} alt="Alex Larson"></ProfilePic>
      <Description>
        Senior Software Engineer with over 10 years of full-stack development experience
      </Description>

      <Flex backgroundColor={Colors.MEDIUM_BLUE} gridTemplateColumns="auto auto">

        <FlexItem>
          <a href="mailto:alexlarson@protonmail.com">
            <FontAwesomeIcon icon={faEnvelopeSquare} color={Colors.GOLDEN} size="3x" />
          </a>
        </FlexItem>

        <FlexItem>
          <a href="https://linkedin.com/in/alex-larson1/">
            <FontAwesomeIcon icon={faLinkedin} color={Colors.GOLDEN} size="3x" />
          </a>
        </FlexItem>
      </Flex>

    </GridContainer>
  </Fragment>
