export const IndependentConsulting = {
  dateRange: 'February 2020 - October 2020',
  company: 'Independent Consulting, Remote',
  bullets: [
    'Consulted for Delta Airlines, RBC, and Dark Heart Games',
    `Developed RESTful API's using Spring Boot, MyBatis, and Oracle SQL`,
    'Developed Web Applications using React',
    'Refactored a Spring Boot application to follow best practices',
    'Wrote over 1,000 unit tests using JUnit, Mockito, and MockMvc to provide 99% code coverage',
    'Developed Microservices using Java, Spring Boot, and LDAP',
    'Developed a reusable REST authentication service using NodeJS with Typescript, Typeorm, MySQL, and Express',
    'Created email services using Nodemailer and Nunjucks'
  ]
}
