export const BestBuy = {
  jobTitle: 'Senior Software Engineer',
  dateRange: 'October 2020 - April 2021',
  company: 'Randstad (Contract with Best Buy)',
  location: 'Remote',
  bullets: [
    `Developed RESTful API's using Spring Boot`,
    'Automated a process of creating Confluence Wiki pages to track deployments using Groovy',
    'Created a Gateway using Kotlin and Spring Cloud Gateway',
    `Created a logging library for Spring Boot API's`,
    'Integrated with Google Maps API'
  ]
}
