export const ThomsonReuters = {
  jobTitle: 'Software Test Engineer',
  dateRange: 'May 2015 -  March 2017',
  company: 'Thomson Reuters',
  location: 'Eagan MN',
  bullets: [
    'Lead a team of testers to replace a FitNesse testing suite with a new Java automation framework',
    'Collaborated with developers and business partners to create test plans and acceptance criteria',
    'Developed and maintained automated tests validating RESTful APIs and application performance',
    'Created automated tests that extract and verify data from Hadoop',
    'Created Oracle database tables and Sqoop data into HDFS',
    'Selenium automation testing using Java'
  ]
}
