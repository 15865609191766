export const TheHomeDepot = {
  jobTitle: 'Senior Software Engineer',
  dateRange: 'February 2024 - Current',
  company: 'The Home Depot',
  location: 'Remote',
  bullets: [
    'Develop and maintain Point of Sale (POS) software using Java',
    'Develop and maintain microservices using Java and Spring Boot',
    'Develop and maintain a self checkout web app using React',
    'Perform research into new features and determine level of effort to complete the feature',
    'Create and maintain developer tools using Nx, React, Next.js, and Express',
    'Utilize k6 to design and execute performance tests, ensuring optimal system scalability and stability under high-traffic conditions for POS services',
    'Implement and maintain security measures within the POS and Self Checkout systems to protect sensitive customer data and ensure compliance with industry standards'
  ]
}
