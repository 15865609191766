export default class Colors {

  static get DARK_BLUE() {
    return '#000022'
  }

  static get LINKEDIN_BLUE() {
    return '#0078B6'
  }

  static get MEDIUM_BLUE() {
    return '#000040'
  }

  static get GOLDEN() {
    return '#d4af37'
  }

  static get SNOW() {
    return '#FFFCFF'
  }
}
