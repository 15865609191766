import styled from 'styled-components'
import { Flex } from '../components/layout/Styles'

export const EducationFlex = styled(Flex)`
  margin-bottom: 10px;

  @media only screen and (min-width: 720px) {
    margin-left: 100px;
  }
`
