import { Fragment } from 'react'
import { Flex, H2 } from '../components/layout/Styles'
import Jobs from '../jobs/index'
import JobComponent from '../jobs/JobComponent'

export const ExperienceComponent = () =>
  <Fragment>
    <H2>Experience</H2>
    <Flex
      flexDirection="column"
      alignItems="stretch"
      margin="auto"
      justifyContent="space-between"
    >
      {
        Jobs.map((job) =>  <JobComponent job={job} key={job.dateRange} />)
      }

    </Flex>
  </Fragment>
