export const Optum = {
  jobTitle: 'Senior Software Engineer',
  dateRange: 'June 2018 - January 2020',
  company: 'Optum',
  location: 'Eden Prairie MN',
  bullets: [
    'Developed RESTful API’s using Spring Boot, Hibernate, and MySQL',
    'Developed Web Apps using Angular 8 ',
    'Partnered closely with team lead to design and drive technical vision ',
    'Collaborated with other developers to design architecture for new projects',
    'Mentored junior developers on coding best practices',
    'Trained coworkers on back end unit and integration testing best practices using Junit, Mockito, and H2',
    'Trained coworkers on front end unit testing best practices using Jasmine and Karma',
    'Identified potential design risks in system design, data integrations, migrations, reporting and infrastructure',
    'Delivered and enforced complete unit test coverage to prove correctness, robustness, and scalability'
  ]
}
