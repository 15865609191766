export const BrookSourceTHD = {
  jobTitle: 'Senior Software Engineer',
  dateRange: 'October 2023 - February 2024',
  company: 'Brooksource (Contract with The Home Depot)',
  location: 'Remote',
  bullets: [
    'Developed and maintained Point of Sale (POS) software using Java',
    'Developed and maintained microservices using Java and Spring Boot',
    'Developed and maintained a self checkout web app using React',
    'Created and ran performance tests using Apache JMeter and Postman',
    'Participated in code reviews'
  ]
}
