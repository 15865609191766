import { Fragment } from 'react'
import { GridItem, H2, List } from '../components/layout/Styles'
import { SkillsContainer } from './Styles'

const skills = [
  { title: 'Languages', values: ['Javascript', 'Typescript', 'Java', 'Python', 'Kotlin', 'Groovy', 'C#'] },
  { title: 'Frameworks', values: ['React', 'React Native', 'Next.js', 'Apollo Graphql', 'Spring Boot', 'NodeJS', 'Angular', 'Express'] },
  { title: 'Applications', values: ['VS Code', 'IntelliJ IDEA', 'PyCharm', 'Eclipse', 'Postman', 'MySQL Workbench', 'TablePlus'] },
  { title: 'Database', values: ['MySQL', 'CosmosDB', 'Oracle', 'MongoDB', 'CockroachDB', 'Microsoft SQL'] }
]

export const SkillsComponent = () =>
  <Fragment>
    <H2>Skills</H2>
    <SkillsContainer>
      {
        skills.map((item) =>
          <GridItem key={item.title}>
            <ul>
              <List fontWeight="bold">{item.title}</List>
              {
                item.values.map((skill) => (
                  <List key={skill}>{skill}</List>
                ))
              }
            </ul>
          </GridItem>
        )
      }
    </SkillsContainer>
  </Fragment>
