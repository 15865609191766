export const PalletGeo = {
  jobTitle: 'React Software Engineer',
  dateRange: 'March 2023 - Current',
  company: 'Pallet Geo',
  location: 'Remote',
  bullets: [
    'Develop and maintain a mobile app using React Native, Expo Go, and TypeScript',
    'Develop and maintain a web app using React, Next.js, and TypeScript',
    `Integrate with API's using Axios and React Query`,
    'Participate in code reviews',
    'Create and track tickets in GitHub'
  ]
}
