export const OptumRx = {
  jobTitle: 'Senior Software Engineer',
  dateRange: 'May 2021 - September 2023',
  company: 'Equity Staffing (Contract with OptumRx)',
  location: 'Remote',
  bullets: [
    'Developed Azure Functions using NodeJS, Typescript, CosmosDB, and GraphQL',
    'Developed and maintained React Web Apps',
    'Implemented SSO using Azure Active Directory',
    'Developed functional and unit tests using Jest and Cypress',
    'Created and maintained report services using Python, CosmosDB, Spring Batch, and MySQL',
  ]
}
