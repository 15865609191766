import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment, useState } from 'react'

import { Card, Flex, FlexItem, List } from '../components/layout/Styles'
import { Details, Description, IconWrapper } from './Styles'

const BulletPoints = ({ bullets }) =>
  <ul>
    {
      bullets.map((bullet, index) => (
        <List
          listStyleType="square"
          key={index}
        >
          {bullet}
        </List>
      ))
    }
  </ul>

const JobComponent = ({ job }) => {
  const [showBullets, setShowBullets] = useState(false)

  const toggleBullets = () => {
    setShowBullets(!showBullets)
  }

  return (
    <Fragment>
      <Card
        margin="16px auto"
        padding="12px"
        width="500px"
        cursor="pointer"
        borderRadius="10px"
        onClick={() => toggleBullets()}
      >

        <Flex>
          <FlexItem>
            <Description>
              <div>{job.jobTitle}</div>
              <div>{job.dateRange}</div>

              {
                job.company && job.location &&

                <div>{job.company}, {job.location}</div>
              }

              {
                job.company && !job.location &&
                <div>{job.company}</div>
              }
            </Description>

            {
              showBullets &&
              <Details isOpen={true}>
                <BulletPoints bullets={job.bullets} />
              </Details>
            }
          </FlexItem>

          <FlexItem margin={"0 0 0 auto"} >
            <IconWrapper shouldRotate={!showBullets}>
              <FontAwesomeIcon icon={faChevronUp} />
            </IconWrapper>
          </FlexItem>

        </Flex>

      </Card>
    </Fragment>
  )
}

export default JobComponent
