export const MaverickSoftwareConsulting = {
  jobTitle: 'Software Engineer Intern',
  dateRange: 'August 2013 - May 2015',
  company: 'Maverick Software Consulting',
  location: 'Mankato MN',
  bullets: [
    'Worked as an Intern with Maverick Software in collaboration with Thomson Reuters',
    'CodedUI and Selenium automation testing using C# with Visual Studio 2012 and Resharper',
    'Analyzed automation test runs daily and communicated results to the rest of the team',
    'Performed and lead code reviews with other members of the team',
    'Attended team meetings over conference calls and Webex',
    'Participated in project planning sessions with team members to analyze project requirements',
    'Worked actively with developers in testing and documentation',
    'Performed application testing and debugging to ensure functionality',
    'Interacted actively with the team in identifying new bugs',
    'Documented new bugs with details on how to reproduce and verify bug fixes in TFS',
    'Created test cases for manual smoke and regression testing',
    'Tested a desktop product on multiple environments (Windows 7/8, Word 2007/2010/2013, Google Chrome, Internet Explorer) through lab manager',
    'Trained new employees on best coding practices and testing techniques',
    'Assisted in the interview process to help evaluate potential employees',
    'Took part in recruitment events and career fairs on campus to increase awareness about Maverick Software Consulting and opportunities for students'
  ]
}
