import styled from 'styled-components'
import { GridContainer } from '../components/layout/Styles'

export const SkillsContainer = styled(GridContainer)`
  grid-template-columns: 25% 25% 25% 25%;

  @media only screen and (max-width: 500px) {
    grid-template-columns: 50% 50%;
  }

  @media only screen and (min-width: 720px) {
    margin-left: 100px;
  }
`
