import styled from 'styled-components'

export const Description = styled.div`
  margin-left: 24px;
`

export const Details = styled.div`
  max-height: ${props => props.isOpen ? '100%' : '0'};
  overflow: hidden;
  transition: all .3s;
`

export const IconWrapper = styled.div`
  float: right;
  transform: rotate(0deg);
  overflow: hidden;
  transition: all 0.3s ease-out;
  transform: ${props => props.shouldRotate ? `rotate(-180deg)` : ''};
`
