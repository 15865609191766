import { Fragment } from 'react'
import { H2 } from '../components/layout/Styles'
import { EducationFlex } from './Styles'

export const EducationComponent = () =>
  <Fragment>
    <H2>Education</H2>

    <EducationFlex
      flexDirection="column"
      alignItems="stretch"
      margin="12px 0 24px 24px"
      justifyContent="space-between"
    >
      <div>Minnesota State University - Mankato</div>
      <div>May 2015</div>
      <div>Bachelor of Science: Information Systems</div>
    </EducationFlex>

  </Fragment>
