import { Fragment, useEffect } from 'react'

import './App.css'
import { HeaderComponent } from './header/HeaderComponent'
import { SkillsComponent } from './skills/SkillsComponent'
import { ExperienceComponent } from './experience/ExperienceComponent'
import { EducationComponent } from './education/EducationComponent'
import { BigCard } from './components/layout/Styles'

const App = () => {

  useEffect(() => {
    document.title = 'Alex Larson'
  })

  return (
    <Fragment>
      <BigCard>
        <HeaderComponent></HeaderComponent>
        <SkillsComponent></SkillsComponent>
        <ExperienceComponent></ExperienceComponent>
        <EducationComponent></EducationComponent>
      </BigCard>
    </Fragment>
  )
}

export default App
