export const BrookSource = {
  jobTitle: 'Senior Software Engineer',
  dateRange: 'March 2017 - June 2018',
  company: 'Brooksource',
  location: 'Minneapolis MN',
  bullets: [
    'Worked as a contractor for Optum through Brooksource',
    'Developed RESTful applications using RESTEasy, Hibernate, MySQL, and Angular 2',
    'Created and maintained CI/CD processes using Jenkins, Artifactory, Docker, and OpenShift',
    'Collaborated daily with QA, Business partners, and other developers',
    'Collaborated with other developers to design architecture for new projects',
    'Performed code reviews and ensured all solutions met architecture specifications',
    'Assisted newer members of the team with backend development, unit, and integration testing',
    'Analyzed and improved code quality and coverage using Sonar',
    'Interviewed new hires alongside other team members'
  ]
}
