import styled from 'styled-components'
import Colors from '../../constants/Colors'

export const BigCard = styled.div`
  background-color: ${Colors.SNOW};
  box-shadow: 0 4px 8px 0 ${Colors.DARK_BLUE};
  padding: 5px;

  @media only screen and (min-width: 1200px) {
    margin: auto;
    width: 80%;
  }
`

export const Card = styled.div`
  box-shadow: 0 4px 8px 0 ${Colors.DARK_BLUE};
  margin: ${props => props.margin ? props.margin : '5px'};
  border: ${props => props.border ? props.border : '0'};
  border-radius: ${props => props.borderRadius ? props.borderRadius : '0'};
  padding: ${props => props.padding ? props.padding : '0'};
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '100%'};
  cursor: ${props => props.cursor ? props.cursor : 'auto'};

  @media only screen and (max-width: 600px) {
    width: 90%;
  }
`

export const Flex = styled.div`
  align-items: ${props => props.alignItems ? props.alignItems : 'flex-start'};
  align-content: ${props => props.display ? props.display : 'center'};
  display: ${props => props.display ? props.display : 'flex'};
  flex-direction: ${props => props.flexDirection ? props.flexDirection : 'row'};
  flex-wrap: ${props => props.flexWrap ? props.flexWrap : 'nowrap'};
  justify-content: ${props => props.justifyContent ? props.justifyContent : 'center'};

  margin: ${props => props.margin ? props.margin : '0'};
  width: ${props => props.width ? props.width : 'auto'};

  background-color: ${props => props.backgroundColor ? props.backgroundColor : Colors.SNOW};
  color: ${props => props.color ? props.color : Colors.DARK_BLUE};
`

export const FlexItem = styled.div`
  margin: ${props => props.margin ? props.margin : '8px'};
`

export const GridContainer = styled.div`
  display: ${props => props.display ?  props.display : 'grid'};
  margin: ${props => props.margin ? props.margin : '0'};
  padding: ${props => props.padding ? props.padding : '0'};
  border-bottom: ${props => props.borderBottom ? props.borderBottom : '0'};
  border-bottom-color: ${props => props.borderBottomColor ? props.borderBottomColor : Colors.SNOW};
  grid-template-columns: ${props => props.gridTemplateColumns ? props.gridTemplateColumns : 'auto'};
  grid-template-rows: ${props => props.gridTemplateRows ? props.gridTemplateRows : 'auto'};
  background-color: ${props => props.backgroundColor ? props.backgroundColor : Colors.SNOW};
  color: ${Colors.DARK_BLUE};
`

export const GridItem = styled.div`
`

export const H2 = styled.h2`
  margin: 0;
  margin-left: 100px;

  background-color: ${props => props.backgroundColor ? props.backgroundColor : Colors.SNOW};
  color: ${props => props.color ? props.color : Colors.DARK_BLUE};

  @media only screen and (max-width: 720px) {
    margin-left: 10px;
  }
`

export const List = styled.li`
  list-style-type: ${props => props.listStyleType ? props.listStyleType : 'none'};
  font-weight: ${props => props.fontWeight ? props.fontWeight : 'normal'};
`
